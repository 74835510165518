const user = {
  detail: {
    id: "",
    full_name: "",
    email: "",
  },
  userRole: "admin",
};

export const login = (state = user, action) => {
  switch (action.type) {
    case "USER_LOGGED_IN": {
      return { ...state, detail: action.detail };
    }

    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole };
    }
    default: {
      return state;
    }
  }
};
