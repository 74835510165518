import { LIST_DOMAIN } from "../const/user";

const initialState = {
  items: [],
  detail: {},
  domains: [],
};
export const user = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_USER": {
      return { ...state, detail: action.detail };
    }

    case LIST_DOMAIN: {
      return { ...state, domains: action.data };
    }

    default: {
      return state;
    }
  }
};

export default user;
