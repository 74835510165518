const initialState = {
  items: [],
  detail: {},
  pageBlockDetail: {},
  blockItems: [],
  builder: {
    modalElement: {},
  },
  pageFound: true,
  errors: {},
  theme: {},
};

export const page = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_THEME": {
      return {
        ...state,
        theme: {
          ...action.theme,
        },
      };
    }

    case "SHOW_MODAL_ELEMENT": {
      return { ...state, builder: action.builder };
    }

    case "PAGE_NOT_FOUND": {
      return { ...state, blockItems: action.blockItems, pageFound: false };
    }

    case "CREATE_PAGE": {
      return { ...state, detail: action.detail };
    }

    case "DELETE_PAGE": {
      return { ...state, detail: action.detail };
    }

    case "LOADED_PAGE_LIST": {
      return { ...state, items: action.items };
    }

    case "FETCH_PAGE_BLOCK_LIST": {
      return { ...state, blockItems: action.blockItems, errors: {} };
    }

    case "CREATE_PAGE_BLOCK": {
      return {
        ...state,
        blockItems: [...state.blockItems, action.data],
        errors: {},
      };
    }

    case "UPDATE_PAGE_BLOCK": {
      return { ...state, blockItems: action.blockItems, errors: {} };
    }

    case "ERROR_PAGE_BLOCK": {
      return { ...state, errors: action.errors };
    }

    case "REORDER_PAGE_BLOCK": {
      let tt = {
        ...state,
        blockItems: action.blockItems,
      };
      console.log(tt);

      return tt;
    }

    case "SHOW_PAGE": {
      return { ...state, detail: action.detail };
    }

    case "SHOW_PAGE_BLOCK": {
      return { ...state, pageBlockDetail: action.pageBlockDetail };
    }

    default: {
      return state;
    }
  }
};

export default page;
