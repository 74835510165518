import React from "react";
import * as Icon from "react-feather";
import { FormattedMessage } from "react-intl";

const horizontalMenuConfig = [
  {
    id: "pos",
    title: <FormattedMessage defaultMessage="Point Of Sale" id="pos" />,
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/app/pos",
    children: [
      {
        id: "pos-database",
        title: <FormattedMessage id="pos.database" defaultMessage="Database" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/app/pos/data",
        children: [
          {
            id: "pos-database-category",
            title: (
              <FormattedMessage
                id="pos.product-category"
                defaultMessage="Product Category"
              />
            ),
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: ["admin"],
            navLink: "/app/pos/product-category",
          },
          {
            id: "pos-database-product",
            title: (
              <FormattedMessage id="pos.product" defaultMessage="Product" />
            ),
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: ["admin", "editor"],
            navLink: "/app/pos/product",
          },
          {
            id: "pos-database-supplier",
            title: (
              <FormattedMessage id="pos.supplier" defaultMessage="Supplier" />
            ),
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: ["admin", "editor"],
            navLink: "/app/pos/supplier",
          },
          {
            id: "pos-database-customer",
            title: (
              <FormattedMessage id="pos.customer" defaultMessage="Customer" />
            ),
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: ["admin", "editor"],
            navLink: "/app/pos/customer",
          },
          {
            id: "pos-database-staff",
            title: <FormattedMessage id="pos.staff" defaultMessage="Staff" />,
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: ["admin", "editor"],
            navLink: "/app/pos/staff",
          },
        ],
      },

      {
        id: "pos-selling",
        title: <FormattedMessage id="pos.selling" defaultMessage="Selling" />,
        type: "item",
        icon: <Icon.PlusCircle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/app/pos/selling",
      },
      {
        id: "pos-purchase",
        title: <FormattedMessage id="pos.purchase" defaultMessage="Purchase" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/app/pos/purchase",
      },
      {
        id: "pos-cash",
        title: <FormattedMessage id="pos.cash" defaultMessage="Cash" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/app/pos/cash",
      },
      {
        id: "pos-report",
        title: (
          <FormattedMessage id="pos.report.report" defaultMessage="Report" />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/app/pos",
        children: [
          {
            id: "pos-report-selling",
            title: (
              <FormattedMessage
                id="pos.selling-reports"
                defaultMessage="Selling Reports"
              />
            ),
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: ["admin"],
            navLink: "/app/pos/report/selling",
          },
          {
            id: "pos-purchase-report",
            title: (
              <FormattedMessage
                id="pos.purchase-reports"
                defaultMessage="Purchase Reports"
              />
            ),
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: ["admin", "editor"],
            navLink: "/app/pos/report/purchase",
          },
          {
            id: "pos-product-stock",
            title: (
              <FormattedMessage
                id="pos.product-stock-report"
                defaultMessage="Product Stock Reports"
              />
            ),
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: ["admin", "editor"],
            navLink: "/app/pos/report/product-stock",
          },
          {
            id: "pos-product-price-history",
            title: (
              <FormattedMessage
                id="pos.product-price-history"
                defaultMessage="Product Price History"
              />
            ),
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: ["admin", "editor"],
            navLink: "/app/pos/report/product-price",
          },
          {
            id: "pos-product-unit",
            title: (
              <FormattedMessage
                id="pos.financial-reports"
                defaultMessage="Cash Reports"
              />
            ),
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: ["admin", "editor"],
            navLink: "/app/pos/report/cash",
          },
        ],
      },
    ],
  },

  {
    id: "pages",
    title: (
      <FormattedMessage defaultMessage="Landing Pages" id="landing-pages" />
    ),
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/app/pages",
  },

  {
    id: "leads",
    title: <FormattedMessage defaultMessage="Leads" id="leads" />,
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/app/leads",
  },
  {
    id: "settings",
    title: <FormattedMessage defaultMessage="Settings" id="settings" />,
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/app/settings",
  },
];

export default horizontalMenuConfig;
