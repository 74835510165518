import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import { ToastContainer } from "react-toastify";
import { IntlProvider } from "react-intl";
import Cookies from "js-cookie";
const LazyApp = lazy(() => import("./App"));

function loadLocaleData(locale) {
  switch (locale) {
    case "id":
      return import("lang/compiled/id.json");
    default:
      return import("lang/compiled/en.json");
  }
}

let lang = Cookies.get('lang') ? Cookies.get('lang') : "en";

// ReactDOM.render(
//   <Provider store={store}>
//     {JSON.stringify(loadLocaleData(lang))}
//     <IntlProvider locale={lang} messages={loadLocaleData(lang)}>
//       <ToastContainer position="bottom-right" />
//       <Suspense fallback={<Spinner />}>
//         <Layout>
//           <LazyApp />
//         </Layout>
//       </Suspense>
//     </IntlProvider>
//   </Provider>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

function App(props) {
  return (
    <IntlProvider
      locale={props.locale}
      defaultLocale="en"
      messages={props.messages}
    >
      <Provider store={store}>
        <ToastContainer position="bottom-right" />
        <Suspense fallback={<Spinner />}>
          <Layout>
            <LazyApp />
          </Layout>
        </Suspense>
      </Provider>
    </IntlProvider>
  );
}

async function bootstrapApplication(locale, mainDiv) {
  const messages = await loadLocaleData(locale);
  ReactDOM.render(<App locale={locale} messages={messages} />, mainDiv);
}

bootstrapApplication(lang, document.getElementById("root"));
