import { PB_STYLE_UPDATE } from "../const/pageBuilder";

const initialState = {
  show: false,
  action: "new",
  style: {},
};

export const pageBuilder = (state = initialState, action) => {
  switch (action.type) {
    case PB_STYLE_UPDATE: {
      return { ...state, style: action.data };
    }

    default: {
      return state;
    }
  }
};

export default pageBuilder;
