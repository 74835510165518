import { SET_TOTAL_LEAD, GET_TOTAL_LEAD } from "../const/insight";

const initialState = {
  total_lead: 0,
};

export const insight = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOTAL_LEAD: {
      return { ...state, total_lead: action.data };
    }

    case GET_TOTAL_LEAD: {
      return { ...state, total_lead: state.total_lead };
    }

    default: {
      return state;
    }
  }
};

export default insight;
