import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
// import createSagaMiddleware from "redux-saga";
// import apiSaga from "../saga/index";

const middlewares = [thunk, createDebounce()];

// const saga = createSagaMiddleware();
// const middlewares = [saga, createDebounce()];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(...middlewares))
// );
const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);
// saga.run(apiSaga);

export { store };
