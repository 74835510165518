const initialState = {
  items: [],
  detail: {},
};
export const block = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_BLOCK_LIST": {
      return { ...state, items: action.items };
    }

    case "FETCH_BLOCK_DETAIL": {
      return { ...state, detail: action.detail };
    }

    default: {
      return state;
    }
  }
};

export default block;
