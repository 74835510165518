import {
  FILE_TAG_INDEX,
  APPEND_FILE,
  GLOBAL_FILE_TAG_INDEX,
  GLOBAL_APPEND_FILE,
  GLOBAL_LIST_FILE,
} from "redux/const/file";

const initialState = {
  items: [],
  itemsMeta: {},
  tags: [],

  globalItems: [],
  globalItemsMeta: {},
  globalTags: [],

  error: {},
};
export const file = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_FILE": {
      let d = [action.data, ...state.items];
      return { ...state, items: d };
    }

    case "ERROR_FILE": {
      return { ...state, error: action.data };
    }

    case "LIST_FILE": {
      return { ...state, items: action.data.data, itemsMeta: action.data.meta };
    }

    case FILE_TAG_INDEX: {
      return { ...state, tags: action.data };
    }

    case APPEND_FILE: {
      return {
        ...state,
        items: [...state.items].concat(action.data.data),
        itemsMeta: action.data.meta,
      };
    }
      
    case GLOBAL_LIST_FILE: {
      return { ...state, globalItems: action.data.data, globalItemsMeta: action.data.meta };
    }      

    case GLOBAL_FILE_TAG_INDEX: {
      return { ...state, globalTags: action.data };
    }

    case GLOBAL_APPEND_FILE: {
      return {
        ...state,
        globalItems: [...state.globalItems].concat(action.data.data),
        globalItemsMeta: action.data.meta,
      };
    }

    default: {
      return state;
    }
  }
};

export default file;
