import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import page from "./page";
import block from "./block";
import file from "./file";
import pageBuilder from "./pageBuilder";
import insight from "./insight";
import user from "./user";

const rootReducer = combineReducers({
  insight: insight,
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  page: page,
  block: block,
  pageBuilder: pageBuilder,
  file: file,
  user: user,
});

export default rootReducer;
